import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import { Loader } from 'semantic-ui-react';
import Axios from "axios";
import Taplist from './Taplist';
import './index.css';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [beers, setBeers] = useState([]);

  const fetchBeersJson = useCallback(async () => {
    Axios.get(`/beers.json?_=${Date.now()}`)
      .then(({ data }) => {
        setBeers(data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchBeersJson();
    const fetchInterval = setInterval(fetchBeersJson, 60000);
    return () => clearInterval(fetchInterval);
  }, [fetchBeersJson]);

  return loading ?
    <Loader active inline='centered' size='massive' style={{ marginTop: "25%" }}/> : 
    <Taplist beers={ beers }/>;
};

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);
