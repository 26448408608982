import _ from "lodash";
import React from 'react'
import { Grid } from 'semantic-ui-react';
import srmToHex from './SrmToHex';
import { Keg } from "./Keg";

const Beer = (props) => (
  <Grid columns={2}>
    <Grid.Row>
      <Grid.Column width={2}>
        <Keg color={`#${srmToHex(props.srm)}`} beerLevel={_.isUndefined(props.kegLevel) ? 100 : props.kegLevel}/>
      </Grid.Column>
      <Grid.Column width={12}>
        <div style={{ marginTop: 6, marginBottom: 8, fontFamily: "'Fredericka the Great', cursive", fontWeight: 400, fontSize: 30 }}>{props.tapNumber}. {props.name}</div>
        <hr/>
        <div style={{ fontSize: 17 }}>{props.beerStyle} | ABV: {props.abv}% | SRM: {props.srm} | IBU: {props.ibu}</div>
        <hr/>
        <div style={{ fontSize: 17 }}>{props.description}</div>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const Empty = (props) => (
  <Grid columns={2}>
    <Grid.Row>
      <Grid.Column width={2}>
        <Keg color="#b0d0e9" beerLevel={100}/>
      </Grid.Column>
      <Grid.Column>
        <div style={{ marginTop: 6, marginBottom: 8, fontFamily: "'Fredericka the Great', cursive", fontWeight: 400, fontSize: 30 }}>{props.tapNumber}. Empty</div>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const beerOrEmpty = (beers, index) => (
  _.isEmpty(beers[index]) ? <Empty tapNumber={index + 1}/> : <Beer tapNumber={index + 1} {...beers[index]}/>
);

export default ({ beers }) => (
  <>
    <h1 style={{ fontFamily: "'Fredericka the Great', cursive", fontWeight: 400, fontSize: 80, textAlign: "center", marginBottom: 40, paddingTop: 40 }}>
      The Brandoon Saloon
    </h1>
    <Grid columns={2} className="beers">
      <Grid.Row>
        <Grid.Column>{beerOrEmpty(beers, 0)}</Grid.Column>
        <Grid.Column>{beerOrEmpty(beers, 1)}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{beerOrEmpty(beers, 2)}</Grid.Column>
        <Grid.Column>{beerOrEmpty(beers, 3)}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{beerOrEmpty(beers, 4)}</Grid.Column>
        <Grid.Column>{beerOrEmpty(beers, 5)}</Grid.Column>
      </Grid.Row>
    </Grid>
  </>
)